body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  padding-bottom: 64px;
  margin-bottom: 64px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* italianno-regular - latin */
@font-face {
  font-family: 'Italianno';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/italianno-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/italianno-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/italianno-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/italianno-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/italianno-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/italianno-v10-latin-regular.svg#Italianno') format('svg'); /* Legacy iOS */
}